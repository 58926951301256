import { Link } from "react-router-dom";
import "./App.css";

export default function App() {
  return (
    <div className="main">
      <h1>Embeddable Dashboards Demo</h1>
      <nav>
        <Link to="/test-client">Test Client Example</Link>
      </nav>
    </div>
  );
}
