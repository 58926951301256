import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.375 6.125c0-.967-.728-1.449-1.228-1.694l-2.701.319c-.403.332-.608.699-.714.978a1.936 1.936 0 0 0-.107.418v.013a.625.625 0 0 1-1.25-.06L6 6.124l-.624-.027v-.012l.001-.019a1.697 1.697 0 0 1 .03-.227c.026-.142.073-.334.156-.555a3.585 3.585 0 0 1 1.088-1.5C7.396 3.17 8.27 3.01 8.99 3c.64-.007 1.272.097 1.704.308h.001c.57.28 1.929 1.073 1.929 2.817 0 1.776-1.189 2.594-2.195 3.286l-.024.016c-.461.317-.707.601-.844.855-.134.25-.187.515-.187.843a.625.625 0 0 1-1.25 0c0-.473.08-.957.337-1.435.255-.475.66-.897 1.235-1.293 1.062-.73 1.678-1.207 1.678-2.272ZM9.008 4.25c.53-.006.945.087 1.139.181l-2.701.319c.445-.368 1-.492 1.562-.5Z"
      fill="#82FB8E"
    />
    <path d="M9.75 13.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" fill="#82FB8E" />
    <circle cx={9} cy={9} r={8.5} stroke="#82FB8E" />
  </svg>
);

export default SvgComponent;
