import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Root from "./routes/test-client";
import Invoices from "./routes/test-client/invoices";
import Usage from "./routes/test-client/usage";
import Credits from "./routes/test-client/credits";
import "./index.css";
import "rsuite/dist/rsuite.min.css";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="test-client" element={<Root />}>
        <Route index element={<Invoices />} />
        <Route path="usage" element={<Usage />} />
        <Route path="credits" element={<Credits />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
