import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#82FB8E" d="M0 0h18v1H0zM0 8.5h18v1H0zM0 17h18v1H0z" />
  </svg>
);

export default SvgComponent;
