import "../../App.css";
import { useState } from "react";
import SettingsDialog, { Settings } from "../../SettingsDialog";
import MenuImage from "./components/menu";
import HelpImage from "./components/help";
import BellImage from "./components/bell";
import GlobeImage from "./components/globe";
import { Outlet } from "react-router-dom";
import styles from "./index.module.css";

function App() {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="App">
      <SettingsDialog
        showDialog={showModal}
        onClose={() => setShowModal(false)}
        onChange={setSettings}
      />
      <header className={styles.appHeader}>
        <div
          className={styles.logo}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <GlobeImage />
        </div>
        <div>
          <BellImage />
          <HelpImage />
          <MenuImage />
        </div>
      </header>
      <Outlet context={settings} />
    </div>
  );
}

export default App;
