import { graphLineColors } from "@metronome-industries/design-system";

const graphColorsData = graphLineColors.reduce((acc, color, index) => {
  return { ...acc, [`UsageLine_${index}`]: color };
}, {});

export enum EnvironmentName {
  Production = "production",
  Staging = "staging",
  Dev = "dev",
  DevWithStagingBackend = "devWithStagingBackend",
  DevWithProductionBackend = "devWithProductionBackend",
}

export interface Environment {
  name: EnvironmentName;
  apiUrl: string;
  graphqlUrl: string;
  dashboardsHost?: URL;
}

export const Constants: {
  environments: Environment[];
  getEnvironment: (name: EnvironmentName) => Environment;
  getDefaultEnvironment: () => Environment;
  ColorDefaults: Record<string, string>;
  DarkModeColorDefaults: Record<string, string>;
  ClientDefaults: Array<{
    name: string;
    overrides: Record<string, string>;
    darkMode: boolean;
    iframeBackgroundColor?: string;
  }>;
} = {
  environments: [
    {
      name: EnvironmentName.Production,
      apiUrl: "https://uxwz1izf4b.execute-api.us-west-2.amazonaws.com/prod",
      graphqlUrl: "https://graphql.metronome.com/v1/graphql",
    },
    {
      name: EnvironmentName.Staging,
      apiUrl: "https://87h5f9pc82.execute-api.us-west-2.amazonaws.com/staging",
      graphqlUrl: "https://graphql.staging.metronome.com/v1/graphql",
    },
    {
      name: EnvironmentName.Dev,
      apiUrl: "http://localhost:8087",
      graphqlUrl: "http://localhost:8080/v1/graphql",
    },
    {
      name: EnvironmentName.DevWithStagingBackend,
      apiUrl: "https://87h5f9pc82.execute-api.us-west-2.amazonaws.com/staging",
      graphqlUrl: "https://graphql.staging.metronome.com/v1/graphql",
      dashboardsHost: new URL("http://localhost:8088"),
    },
    {
      name: EnvironmentName.DevWithProductionBackend,
      apiUrl: "https://uxwz1izf4b.execute-api.us-west-2.amazonaws.com/prod",
      graphqlUrl: "https://graphql.metronome.com/v1/graphql",
      dashboardsHost: new URL("http://localhost:8090"),
    },
  ],
  getEnvironment: (name: EnvironmentName) => {
    const env = Constants.environments.find((e) => e.name === name);
    if (!env) {
      throw Error(`Environment ${name} not found`);
    }

    return env;
  },
  getDefaultEnvironment: () => {
    if (window.location.origin.includes("staging.metronome.com")) {
      return Constants.getEnvironment(EnvironmentName.Staging);
    } else if (window.location.origin.includes("metronome.com")) {
      return Constants.getEnvironment(EnvironmentName.Production);
    } else {
      return Constants.getEnvironment(EnvironmentName.DevWithStagingBackend);
    }
  },
  /*
    keep this in sync with the following :( :
    - api/src/handlers/embeddableDashboard/index.ts
    - api/openapi/versions/v1.yml
    - embeddable-dashboards/src/pages/customer/CustomerDashboard.tsx
    - dashboard-playground/src/constants.ts
  */
  ColorDefaults: {
    Gray_dark: "#363b41",
    Gray_medium: "#6c7682",
    Gray_light: "#dce0e6",
    Gray_extralight: "#f2f3f5",
    White: "#ffffff",
    Primary_medium: "#046eef",
    Primary_light: "#f1f7fe",
    Primary_green: "#17a04e",
    Primary_red: "#d62424",
    ...graphColorsData,
  },
  DarkModeColorDefaults: {
    Gray_dark: "#fb00f5",
    Gray_medium: "#b050e8",
    Gray_light: "#fb00f5",
    Gray_extralight: "#fb00f5",
    White: "#000000",
    Primary_medium: "#ffffff",
    Primary_light: "#fb00f5",
    Primary_green: "#8459ca",
    Primary_red: "#4baaf2",
    ...graphColorsData,
  },
  ClientDefaults: [
    {
      name: "custom",
      overrides: {},
      darkMode: false,
    },
    {
      name: "anthropic",
      overrides: {
        White: "#2b2b2b",
        Primary_medium: "#ffffff",
        Primary_light: "#3a3a3a",
        Gray_dark: "#ffffff",
        Gray_medium: "#c9685f",
        Gray_light: "#6b6b6b",
        Gray_extralight: "#6b6b6b",
      },
      darkMode: true,
      iframeBackgroundColor: "#2b2b2b",
    },
    {
      name: "salad",
      overrides: {
        Gray_dark: "#0a2133",
        Gray_medium: "#6c7682",
        Gray_light: "#d3dae0",
        Gray_extralight: "#d3dae0",
        White: "#ffffff",
        Primary_medium: "#1f4f22",
        Primary_light: "#edf8e0",
      },
      darkMode: false,
    },
    {
      name: "propel",
      overrides: { Primary_medium: "#7f56d9", Primary_light: "#eff1f5" },
      darkMode: false,
    },
    {
      name: "astronomer",
      overrides: {
        Primary_medium: "#32b8fb",
        Primary_light: "#eefaff",
        Gray_dark: "#1a1c1f",
        Gray_medium: "#95979c",
        Gray_light: "#f2f3f7",
        Gray_extralight: "#fbfcfd",
        White: "#fff",
      },
      darkMode: false,
    },
  ],
};
