import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.486 15.77v.259a2.971 2.971 0 0 0 5.943 0v-.26M1 15.308h16v-2.154a2 2 0 0 0-2-2h-.743V6a5 5 0 0 0-5-5h-.514a5 5 0 0 0-5 5v5.154H3a2 2 0 0 0-2 2v2.154Z"
      stroke="#82FB8E"
    />
  </svg>
);

export default SvgComponent;
