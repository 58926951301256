import styles from "./index.module.css";
import { useNavigate, useOutletContext } from "react-router-dom";

import { useState, useEffect } from "react";
import { Settings } from "SettingsDialog";

export default function Credits() {
  const [iframeSrc, setIFrameSrc] = useState("");
  const settings = useOutletContext<Settings>();
  const navigate = useNavigate();
  useEffect(() => {
    async function load() {
      if (settings) {
        const response = await fetch(
          `${settings.environment.apiUrl}/experimentalDashboardUrl`,
          {
            method: "POST",
            body: JSON.stringify({
              customer_id: settings.customerId,
              dashboard: "credits",
              color_overrides: Object.entries(settings.colorOverrides).map(
                ([name, value]) => ({ name, value })
              ),
              dashboard_options: settings.dashboardOptions.filter(
                (opt) => opt.key === "hide_grant_name"
              ),
            }),
            headers: {
              Authorization: `Bearer ${settings.apiToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const {
          data: { url },
        } = await response.json();

        const parsedUrl = new URL(url);
        const override =
          settings.dashboardsHostOverride ||
          settings.environment.dashboardsHost;
        if (override) {
          parsedUrl.host = override.host;
          parsedUrl.port = override.port;
          parsedUrl.protocol = override.protocol;
        }

        parsedUrl.searchParams.set(
          "graphqlUrl",
          settings.environment.graphqlUrl
        );
        setIFrameSrc(parsedUrl.toString());
      }
    }

    load();
  }, [settings]);

  return (
    <div className={styles.contentContainer} id="contentContainer">
      <div>
        <div className={styles.headingFont}>Billing & usage</div>
        <div className={styles.tabs}>
          <div
            onClick={() => {
              navigate("/test-client");
            }}
          >
            Invoices
          </div>
          <div
            onClick={() => {
              navigate("/test-client/usage");
            }}
          >
            Usage
          </div>
          <div className={styles.selectedTab}>Credits</div>
          <div className={`${styles.lastTab} ${styles.notAllowed}`}>Alerts</div>
        </div>
      </div>
      <div className={styles.dashboardContainer}>
        <iframe title="Credits" id="dashboard" src={iframeSrc}></iframe>
      </div>
    </div>
  );
}
