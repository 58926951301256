import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={15} cy={15} r={14} stroke="#82FB8E" />
    <ellipse
      cx={15}
      cy={15.001}
      rx={7.485}
      ry={14}
      transform="rotate(90 15 15)"
      stroke="#82FB8E"
    />
    <ellipse
      cx={15}
      cy={15}
      rx={7.485}
      ry={14}
      transform="rotate(-180 15 15)"
      stroke="#82FB8E"
    />
    <path
      fill="#fff"
      stroke="#82FB8E"
      strokeWidth={0.452}
      d="M14.775 1.226h.452v27.548h-.452z"
    />
    <path
      fill="#fff"
      stroke="#82FB8E"
      strokeWidth={0.452}
      d="M28.774 14.772v.453H1.226v-.452z"
    />
  </svg>
);

export default SvgComponent;
